import React from 'react';
import styled from 'styled-components';

import topLogoImg from '../resource/mobileHome/top_logo@2x.png';
import mainLogoImg from '../resource/mobileHome/banner@2x.png';

import Part1 from '../component/mobileHome/part1';
import Part2 from '../component/mobileHome/part2';
import Part3 from '../component/mobileHome/part3';

import footerBcgImg from '../resource/mobileHome/footer_bcg.png';

const Container = styled.div`
  width: 25.875rem;
  min-height: 100vmax;
  background: #fff;
`;

const HeaderBox = styled.div`
  height: 3.0625rem;
`;
const HeaderLogo = styled.img`
  height: 1.125rem;
  width: 7.75rem;
  object-fit: fill;
  margin-top: 0.5rem;
  margin-left: calc(18px / 2);
`;
const MainLogoBox = styled.div`
  height: 13.375rem;
  padding: 0 0.5625rem;
  overflow: hidden;
  margin-bottom: 2.5rem;
`;
const MainLogo = styled.img`
  width: 100%;
  object-fit: fill;
`;

const FooterBox = styled.div`
  height: 9.4688rem;
  position: relative;
  align-items: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const FooterBcg = styled.img`
  height: 13.3125rem;
  position: absolute;
  right: 0;
  bottom: 0;
`;
const FooterText1 = styled.span`
  font-family: PingFangSC-Semibold;
  font-size: 12px;
  color: #252525;
  letter-spacing: 0;
  text-align: justify;
  position: relative;
  margin-top: 2.375rem;
`;
const FooterText2 = styled.span`
  font-family: PingFangSC-Regular;
  font-size: calc(23px / 2);
  color: #505050;
  letter-spacing: 0;
  text-align: justify;
  position: relative;
  margin-top: 0.8438rem;
`;
const FooterUnderText = styled.span`
  font-family: PingFangSC-Regular;
  font-size: 9px;
  color: #969696;
  letter-spacing: 0;
  text-align: justify;
  position: relative;
  margin-top: 1.4688rem;
`;

export default function WebHome(props) {
  return (
    <Container>
      <HeaderBox>
        <HeaderLogo src={topLogoImg} />
      </HeaderBox>

      <MainLogoBox>
        <MainLogo src={mainLogoImg} />
      </MainLogoBox>

      <Part1 />
      <Part2 />
      <Part3 />      

      <FooterBox>
        <FooterBcg src={footerBcgImg} />
        <FooterText1>联系我们</FooterText1>
        <FooterText2>联系邮箱：contact@soso-code.com<br />商务邮箱：business@soso-code.com</FooterText2>
        <FooterUnderText>京ICP备17020842号-1</FooterUnderText>
      </FooterBox>
    </Container>
  );
}
