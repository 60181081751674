import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

export default function InformationList(props) {
  let dataArr;
  if (!Array.isArray(props.data)) {
    dataArr = [props.data];
  } else { // props.data is an object
    dataArr = props.data;
  }
  const items = [];
  for (let i = 0; i < props.pageSize; i++) {
    let itemData;
    if (i < dataArr.length) {
      const val = dataArr[i];
      itemData = {
        id: val.id,
        title: val.title,
        subTitle: getTimeString(val.created),
        previewText: val.brief,
        bottomText: val.infocontent ? '查看全文' : '',
        img: val.poster,
        newslink: val.newslink,
        infocontent: val.infocontent
      };
    } else {
      break;
    }
    items.push(
      <ListItem
        key={`informationListItem${i}`}
        {...itemData} />
    );
  }
  return <ListContainer children={items} />;
}

function getTimeString(timeStamp) { // 单位为秒
  const numToTimeStr = function (num) {
    // return num / 10 > 1 ? '' + num : '0' + num;
    if (num < 10) {
      return `0${num}`;
    }

    return num;
  }
  const date = new Date(timeStamp);
  const year = date.getFullYear();
  const month = numToTimeStr(date.getMonth() + 1);
  const day = numToTimeStr(date.getDate());

  const hour = numToTimeStr(date.getHours());
  const min = numToTimeStr(date.getMinutes());
  const sec = numToTimeStr(date.getSeconds());

  return `${year}-${month}-${day} ${hour}:${min}`;
}

InformationList.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  pageSize: PropTypes.number
};
InformationList.defaultProps = {
  data: [],
  pageSize: 5
}

const ListContainer = styled.div`
  /* height: 62.5rem; */
  width: 47.5rem;
`;
const ListItemContainer = styled.a`
  width: 100%;
  justify-content: space-between;
  height: 12.5rem;
  box-sizing: border-box;
  padding: 1.3125rem 1.125rem 1.3125rem 0.6875rem;
  display: flex;
  background: #fff;
  transition-duration: 0.5s;
  &:hover {
    box-shadow: 0 0 12px rgba(204,204,204,0.6);
    z-index: 1;
    position: relative;
    cursor: pointer;
  }
`;
const LeftImageContainer = styled.div`
  width: 15rem;
  height: 9.875rem;
  overflow: hidden;
  background-color: #e6e6e6;
`;
const LeftImage = styled.img.attrs(props => ({
  display: props.src ? 'block' : 'none'
}))`
  width: 100%;
  height: 100%;
  border: none;
  object-fit: cover;
  transition-duration: 1s;
  display: ${props => props.display};
  ${ListItemContainer}:hover & {
    transform: scale(1.08)
  }
`;
const RightInfoContainer = styled.div`
  width: 29.375rem;
  /* background-color: darkorange; */
`;
const RightTitle = styled.div`
  font-family: Helvetica;
  font-size: 20px;
  color: #444444;
  width: 100%;
  height: 1.625rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
const RightSubTitle = styled.div`
  font-family: Helvetica;
  font-size: 12px;
  color: #999999;
  width: 100%;
  height: 1rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-top: 6px;
`;
const RightPreviewText = styled.div`
  font-family: Helvetica;
  font-size: 11px;
  color: #666666;
  letter-spacing: 0;
  text-align: justify;
  line-height: 24px;
  width: 100%;
  height: 4.5rem;
  overflow: hidden;
  margin-top: 0.6875rem;
`;
const RightBottomText = styled.div`
  font-family: Helvetica;
  font-size: 13px;
  color: #007AFF;
  height: 1.0625rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-top: 0.25rem;
`;
const ListItem = props => {
  return (
    <ListItemContainer target="_blank" onClick={() => {
      if (props.infocontent) {
        window.open(`https://${window.location.host}/infodetail?tag=${props.id}`, '_blank');
      }
      
    }}>
      <LeftImageContainer>
        <LeftImage src={props.img} />
      </LeftImageContainer>
      <RightInfoContainer>
        <RightTitle>{props.title}</RightTitle>
        <RightSubTitle>{props.subTitle}</RightSubTitle>
        <RightPreviewText>{props.previewText}</RightPreviewText>
        <RightBottomText>{props.bottomText}</RightBottomText>
      </RightInfoContainer>
    </ListItemContainer>
  );
};
