import * as Apis from '../../constants/apiConstants';
import { SERVICE_CODE } from "../../constants/dominConstants";
import { launchPOSTRequest } from '../../util/request';
import md5 from'js-md5';

export async function launchGetWebInfoList(
    params = {},
    success = () => {},
    failed = () => {}
  ) {
    try {
        const response = await launchPOSTRequest(Apis.GET_WEB_INFO_LIST, params);
        if (
            response.result.status === SERVICE_CODE.Successed
        ) {
            success(response.result);
        } else {
            failed(response.msg);
        }
    } catch (rejectedValue) {
        failed(JSON.stringify(rejectedValue));
    } 
}

export async function launchGetWebInfoDetail(
    params = {},
    success = () => {},
    failed = () => {}
  ) {
    try {
        const response = await launchPOSTRequest(Apis.GET_WEB_INFO_DETAIL, params);
        if (
            response.result.status === SERVICE_CODE.Successed
        ) {
            success(response.result);
        } else {
            failed(response.msg);
        }
    } catch (rejectedValue) {
        failed(JSON.stringify(rejectedValue));
    } 
}