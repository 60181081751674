import styled from 'styled-components';

export const PartTextBox = styled.div`
  width: 100%;
  position: absolute;
`;

export const PartTextTitle = styled.div`
  height: 4.0313rem;
  font-family: PingFangSC-Semibold;
  font-size: 46px;
  color: #68AAF7;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0.5rem;
`;

export const PartTextSubTitle = styled.div`
  font-family: PingFangSC-Regular;
  font-size: 12.5px;
  color: #6F6F6F;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
