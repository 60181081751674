import React from 'react';
import styled, { keyframes } from 'styled-components';

import { PartTextBox, PartTextTitle, PartTextSubTitle } from './partText';

import BcgImg from '../../resource/mobileHome/technical services/bcg.png';
import WindowImg from '../../resource/mobileHome/technical services/window.png';
import ManImg from '../../resource/mobileHome/technical services/man.png';
import FlowerImg from '../../resource/mobileHome/technical services/flower.png';
import WomanImg from '../../resource/mobileHome/technical services/woman.png';
import JavaImg from '../../resource/mobileHome/technical services/java.png';
import CppImg from '../../resource/mobileHome/technical services/c++.png';
import BubbleImg from '../../resource/mobileHome/technical services/bubble.png';
import PhpImg from '../../resource/mobileHome/technical services/php.png';
import HelloWorldImg from '../../resource/mobileHome/technical services/helloworld.png';

const Box = styled.div`
  height: 32.3438rem;
  width: 100%;
  margin-bottom: 2.4063rem;
  position: relative;
  overflow: hidden;
`;
const Img = styled.img`
  position: absolute;
`;
const Bcg = styled(Img)`
  width: 23rem;
  height: 32.3438rem;
  right: 0;
`;
const Window = styled(Img)`
  height: 12.5625rem;
  top: 5.0625rem;
  right: 4.375rem;
`;
const Man = styled(Img)`
  width: 4.25rem;
  top: 8.7188rem;
  right: 2.7188rem;
`;
const Flower = styled(Img)`
  width: 2rem;
  top: 15.1563rem;
  left: 0.1875rem;
`;
const Woman = styled(Img)`
  width: 2.5rem;
  top: 64px;
  left: 162.5008px;
`;
const Bubble = styled(Img)`
  width: 3.375rem;
  height: 2.6563rem;
  top: 23.5008px;
  right: 8.125rem;
`;
const shake = function (deg) {
  return keyframes`
    0% {
      transform: rotate(0);
    }
    15% {
      transform: rotate(${deg}deg);
    }
    50% {
      transform: rotate(${deg}deg);
    }
    65% {
      transform: rotate(0);
    }
  `;
};
const Php = styled(Img)`
  width: 1.8125rem;
  top: 15.6563rem;
  left: 15.4038rem;
  animation: ${shake(-20)} 1.5s linear infinite;
  transform-origin: 50% 100%;
  box-shadow: 0 2px 3.5px #c798e7;
  border-radius: 50%;
`;
const Java = styled(Img)`
  width: 1.625rem;
  top: 14.5938rem;
  left: 14.4688rem;
  animation: ${shake(-15)} 1.5s linear infinite;
  transform-origin: 50% 80%;
  box-shadow: 0 2px 3.5px #b6c1e8;
  border-radius: 50%;
`;
const Cpp = styled(Img)`
  width: 1.625rem;
  top: 15.875rem;
  left: 14rem;
  animation: ${shake(-18)} 1.5s linear infinite;
  transform-origin: 50% 80%;
  box-shadow: 0 2px 3.5px #7591EE;
  border-radius: 50%;
`;
const Part1TextBox = styled(PartTextBox)`
  position: absolute;
  top: 21.0313rem;
`;
const HelloWorld = styled.img`
  position: absolute;
  width: 2.025rem;
  height: 1.25rem;
  top: 34px;
  left: 204px; 
`;

export default function () {
  return (
    <Box>
      <Bcg src={BcgImg} />
      <Window src={WindowImg} />
      <Man src={ManImg} />
      <Flower src={FlowerImg} />
      <Woman src={WomanImg} />
      <Java src={JavaImg} />
      <Php src={PhpImg} />
      <Cpp src={CppImg} />
      <Bubble src={BubbleImg} />
      <HelloWorld src={HelloWorldImg} />
      <Part1TextBox>
        <PartTextTitle>技术服务</PartTextTitle>
        <PartTextSubTitle>与靠谱的技术合作，你只差一个嗖嗖编程</PartTextSubTitle>
        <PartTextSubTitle>更优质的服务，更优惠的价格</PartTextSubTitle>
      </Part1TextBox>
    </Box>
  );
};
