import * as DominConfigs from '../constants/dominConstants';
import * as APIs from '../constants/apiConstants';
import LLCDateHelper from 'date-helper';
import md5 from 'js-md5';
import cookie from 'cookie-lza';
import { message } from 'antd';

function checkStatus(response) {
  if (response.result.status >= 200 && response.result.status < 300) {
    return response;
  }

  const error = new Error(response.statusText);
  error.response = response;
  throw error;
}

/**
 * Requests a URL, returning a promise.
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 * @return {object}           An object containing either "data" or "err"
 */
export default async function request(url, options) {
  const response = await fetch(url, options);
  checkStatus(response);
  return await response.json();
}

export async function launchPOSTRequest(
  url,
  params = {},
  requestTyp = DominConfigs.REQUEST_TYPE.POST,
  ignoreParam = false
) {
  const privateKey = DominConfigs.PRIVATE_KEY;

  // headers
  const t = LLCDateHelper.achiveTimestampOfSecond();
  const os = 'opt';

  let headers = new Headers({
    Accept: '*/*',
    'Content-Type': 'application/json',
    Connection: 'keep-alive',
    c: 'opa',
    t,
    os,
    sign: md5(`${privateKey}${t}`)
  });
  // let user = localStorage.getItem('user');
  
  let accesstoken = cookie.get('med_accesstoken');

  if (accesstoken) {
    // user = JSON.parse(user);
    headers.append('accesstoken', accesstoken);
  };
  const fetchParams = {
    method: requestTyp,
    headers
  };

  // 根据不同的请求类型 拼装请求参数
  if (requestTyp === DominConfigs.REQUEST_TYPE.POST) {
    fetchParams.body = JSON.stringify(params);
  } else if (requestTyp === DominConfigs.REQUEST_TYPE.GET) {
    if (!ignoreParam) {
      const allKey = Object.keys(params);
      if (allKey && allKey.length > 0) {
        if (url.indexOf('?') === -1) {
          url = `${url}?`;
        } else {
          if (url.indexOf('=') !== -1) {
            url = url + '&';
          }
        }
        let query = '';
        for (let i = 0; i < allKey.length; i += 1) {
          query = `${query + allKey[i]}=${params[allKey[i]]}`;
          if (i !== allKey.length - 1) {
            query = `${query}&`;
          }
        }
        url = url + query;
      }
    }
  } else if (requestTyp === DominConfigs.REQUEST_TYPE.PUT) {
    fetchParams.body = JSON.stringify(params);
  }

  console.log(
    `-----------------\n发起${requestTyp}请求\n` +
    `* url: ${url}\n` +
    `* params:`
  );
  console.dir(params);
  const response = await fetch(url, fetchParams);
  if (response.status !== 200) {
    
    const errorText = `${response.status}: ${response.statusText}`
    message.error(errorText);
    throw new Error(errorText);
  }
  const responseData = await response.json();

  console.log('请求返回: status:' + responseData.succ + '\n');
  console.dir(responseData);
  console.log('-----------------')
  // AccessToken失效
  
  if (responseData.succ === DominConfigs.RESPONSE_CODE.BadAccessToken) {
    cookie.set('med_status', 0);
      localStorage.clear();
      cookie.del('med_accesstoken');
      cookie.del('med_refreshtoken');
      cookie.del('med_status');
      cookie.del('med_uname');
      cookie.del('med_role');
    message.error('用户信息失效,请重新登录', 2.5, () => {
      window.location.href = '/login';
    });
    throw new Error(`${DominConfigs.RESPONSE_CODE.BadAccessToken}`);
  } else {
    
    if (responseData.succ === DominConfigs.RESPONSE_CODE.success) {
      // 请求成功 succ: 200 && result.status: 1
      if (responseData.result) {
        if (responseData.result.status &&
          responseData.result.status === DominConfigs.SERVICE_CODE.Successed) {
          responseData.succ = true;
          return responseData;
          // return { succ: responseData.succ, responseData };
        } else {
          responseData.succ = false;
          return responseData;
          // return { msg: responseData.msg, responseData };
        }
      } else {
        // return { msg: responseData.msg, responseData };
        responseData.succ = false;
        return responseData;
      }
    } else if (
      responseData.succ === DominConfigs.RESPONSE_CODE.BadRefreshToken
    ) {
      // RefreshToken失效 重新登录
      // GlobalStore.dispatch(requestLogout());
    } else {
      // 请求失败
      debugger
      cookie.set('med_status', 0);
      message.error('未知错误, 将返回登录页面', 2.5, () => {
        window.location.href = '/login';
      });
      throw new Error(`${DominConfigs.RESPONSE_CODE.BadAccessToken}`);
    }
    // return { succ: responseData.succ, responseData };
  }

}



export function uploadFileToQiniu(
  file,
  path,
  success = () => { },
  failed = () => { }
) {
 
      // 上传图片到七牛
  const formdata = new FormData();
  formdata.append('key', path.key);
  formdata.append('token', path.token);
  formdata.append('file', file.originFileObj);
  launchUploadRequest(
    APIs.UPLOAD_TO_QiNiu,
    formdata
  ).then(([status, responseData]) => {
    if (status === DominConfigs.RESPONSE_CODE.success) {
      success();
    } else {
      failed();
    }
  }).catch(error => {
    console.log(error);
    failed('网络异常');
  });
}







/**
 * 发起上传请求
 * @param url 请求地址
 * @param formdata 上传的数据
 * @returns {Promise<Response>}
 */
 export function launchUploadRequest(url, formdata) {
  // const headers = {
  //   'Content-Type': 'multipart/form-data'
  //   // 'Content-Length': '235922'
  // };

  console.log(
    `${'\n发起请求-----------------\n' +
    '* url:\n'}${url}\n*` +
    `* params:\n${JSON.stringify(formdata)}\n-----------------`
  );

  const fetchParams = {
    method: 'POST',
    // headers,
    body: formdata
  };

  return fetch(url, fetchParams).then(response => {
    return response.json().then(responseData => {
      console.log('请求返回: status:' + responseData.succ + ' ,\n');
      console.dir(responseData);
      console.log('-----------------');

      return [response.status, responseData];
    });
  });
}

// /**
//  * 上传项目作品照片到七牛
//  *
//  * result: {
//  *      url: 照片展示url
//  *      key: 上传用key
//  * }
//  *
//  * @param imagePath 照片路径
//  * @param success
//  * @param failed
//  * @returns {function(*)}
//  */
// export function uploadProjectImageToQiniu(
//   image,
//   success = () => { },
//   failed = () => { }
// ) {
//   getUploadPorjectToken(
//     tokenResponse => {
//       // 上传图片到七牛
//       const formdata = new FormData();
//       formdata.append('key', tokenResponse.result.key);
//       formdata.append('token', tokenResponse.result.uploadtoken);
//       formdata.append('file', image.originFileObj);
//       launchUploadRequest(
//         APIs.UPLOAD_TO_QiNiu,
//         formdata
//       ).then(([status, responseData]) => {
//         if (status === DominConfigs.RESPONSE_CODE.success) {
//           success(tokenResponse);
//         } else {
//           failed(responseData.Message);
//         }
//       }).catch(error => {
//         console.log(error);
//         failed('网络异常');
//       });

//     },
//     (msg, faildResponse) => {
//       failed(msg, faildResponse);
//     }
//   )
// }

// /**
//  * 获取上传作品Token
//  * @param success
//  * @param failed
//  * @returns {function(*)}
//  */
// function getUploadPorjectToken(
//   success = () => { },
//   failed = () => { }
// ) {
//   // return dispatch => {
//   const params = {
//     uploadtype: 1
//   };
//   launchPOSTRequest(
//     APIs.GetUploadToken,
//     params,
//     DominConfigs.REQUEST_TYPE.POST
//   ).then(res => {
//     success(res.responseData);
//   }).catch(error => {
//     console.log(error);
//     failed('网络异常');
//   });
// }

// /**
//  * 获取图片的宽高
//  * @param {String} imgUrl 
//  */
// export function getWidthAndHeight(imgUrl) {
//   return new Promise((resolve, reject) => {
//     let img = new Image();
//     img.src = imgUrl;
//     img.onload = () => {
//       let w = img.width;
//       let h = img.height;
//       resolve({ w, h });
//     }
//     img.onerror = () => {
//       reject()
//     }
//   })
// }

// export function createRequestByApi(api) {
//   return async function ({
//     params = {},
//     success = () => { },
//     failed = () => { },
//     complete = () => { }
//   }) {
//     try {
//       const response = await launchPOSTRequest(api, params);
//       if (response.succ) {
//         success(response);
//       } else {
//         failed(response);
//       }
//       complete();
//     } catch (err) {
//       failed(err);
//       complete();
//     }
//   }
// }
/**
 * ↑
 * 使用方法: 
 * 
 * 1. 创建一个请求函数
 * export const saveSomething = createRequestByApi(Apis.dosomething_api);
 * 
 * 2. 调用上面这个函数
 * onSaveTapped = data => {
    const success = res => {
      message.success('保存成功');
    };
    const failed = res => {
      message.error('保存失败');
    };
    saveSomething({
      params: data,
      success,
      failed
    });
  } 
 */