import React from 'react';
import styled, { keyframes } from 'styled-components';

import { PartTextBox, PartTextTitle, PartTextSubTitle } from './partText';

import BcgImg from '../../resource/mobileHome/creative design/bcg.png';
import HighlightImg from '../../resource/mobileHome/creative design/hightlight.png';
import ManImg from '../../resource/mobileHome/creative design/man.png';
import WomanImg from '../../resource/mobileHome/creative design/woman.png';
import PinkBoardImg from '../../resource/mobileHome/creative design/pink_board.png';
import LogoPlateImg from '../../resource/mobileHome/creative design/logo_plate.png';
import HoistImg from '../../resource/mobileHome/creative design/hoist.png';
import LadderImg from '../../resource/mobileHome/creative design/ladder.png';
import ImacImg from '../../resource/mobileHome/creative design/imac.png';

const shake = function (a, b) {
  return keyframes`
    0% { transform: rotate(${a}deg); }
    15% { transform: rotate(${b}deg); }
    50% { transform: rotate(${b}deg); }
    65% { transform: rotate(${a}deg); }
    100% { transform: rotate(${a}deg); }
  `;
};
const swing = function (a, b) {
  return keyframes`
    0% { transform: rotate(${a}deg); }
    50% { transform: rotate(${b}deg); }
    100% { transform: rotate(${a}deg); }
  `;
};
const Box = styled.div`
  height: 31.875rem;
  width: 100%;
  position: relative;
  overflow: hidden;
`;
const Img = styled.img`
  position: absolute;
`;
const Bcg = styled(Img)`
  width: 100%;
  top: 3.6875rem;
`;
const hightlightMove = keyframes`
  0% { transform: translateX(0rem); }
  25% { transform: translateX(0rem); }
  45% { transform: translateX(-8rem); }
  100% { transform: translateX(-8rem); }
`;
const Highlight = styled(Img)`
  width: 2.625rem;
  height: 2.1875rem;
  left: 7rem;
  animation: ${hightlightMove} 2s linear infinite;
  /* animation-delay: 1s; */
`;
const Man = styled(Img)`
  height: 4.3125rem;
  top: 12.75rem;
  left: 6.4688rem;
`;
const womanShake = keyframes`
    0% { transform: rotate(3deg); }
    15% { transform: rotate(-3deg); }
    50% { transform: rotate(-3deg); }
    65% { transform: rotate(3deg); }
  `;
const Woman = styled(Img)`
  width: 3.3125rem;
  height: 3.1563rem;
  top: 11.2813rem;
  right: 4.95rem;
  transform: rotate(3deg);
  animation: ${womanShake} 1.5s linear infinite;
`;
const PinkBoard = styled(Img)`
  width: 4.6875rem;
  height: 2.1875rem;
  top: 0;
  left: 2.2188rem;
`;
const PinkTextBox = styled.div`
  width: 4.6875rem;
  height: 2.1875rem;
  position: absolute;
  top: 0;
  left: 2.2188rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const textScale = keyframes`
  3% { transform: scale(1.15)}
  6% { transform: scale(1.05)}
  9% { transform: scale(1.15)}
  50% { transform: scale(1.15)}
  53% { transform: scale(1)}
  56% { transform: scale(1.1)}
  59% { transform: scale(1)}
`;
const PinkText = styled.span`
  font-family: PingFangSC-Semibold;
  font-size: 12px;
  color: #FFFFFF;
  text-align: center;
  animation: ${textScale} 4s linear infinite;
`;
const LogoPlate = styled(Img)`
  height: 1.5rem;
  top: 8.5938rem;
  left: 5.7188rem;
  transform-origin: 50% 0;
  /* animation: ${shake(-10, 10)} 2s linear infinite; */
  animation: ${swing(-13, 13)} 2s ease-in-out infinite;
`;
const Hoist = styled(Img)`
  width: 7.5313rem;
  top: 2.1875rem;
  left: 0;
`;
const Ladder = styled(Img)`
  width: 2.7813rem;
  height: 2.6563rem;
  top: 14.425rem;
  left: 16.475rem;
`;
const Imac = styled(Img)`
  width: 11.5rem;
  top: 8.0313rem;
  left: 5.875rem;
`;
const TextBox = styled(PartTextBox)`
  top: 20.25rem;
`;

export default function () {
  return (
    <Box>
      <Bcg src={BcgImg} />
      <Imac src={ImacImg} />
      <Man src={ManImg} />
      <Woman src={WomanImg} />
      <PinkBoard src={PinkBoardImg} />
      <PinkTextBox>
        <PinkText>
          正在设计中
        </PinkText>
      </PinkTextBox>
      <Highlight src={HighlightImg} />
      <LogoPlate src={LogoPlateImg} />
      <Hoist src={HoistImg} />
      <Ladder src={LadderImg} />
      <TextBox>
        <PartTextTitle>创意设计</PartTextTitle>
        <PartTextSubTitle>表达产品的亮点，丰富产品的表现，专注于用户的体验</PartTextSubTitle>
        <PartTextSubTitle>让产品更加出众，在众多竞品中脱颖而出</PartTextSubTitle>
      </TextBox>
    </Box>
  );
};
