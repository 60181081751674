import React, { PureComponent } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';


export default class Paging extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { currPageNum: 1 };
  }
  handleBtnClick = (idx) => {
    return () => {
      this.setState({ currPageNum: idx });
      this.props.getListInfo(idx);
    }
  }
  render() {
    const btns = [];
    let totalPageNum;
    if (this.props.totalPageNum < 0) {
      totalPageNum = 1
    } else if (this.props.totalPageNum > 10) {
      totalPageNum = 10;
    } else {
      totalPageNum = this.props.totalPageNum;
    }    
    for (let i = 1; i <= totalPageNum; i++) {
      btns.push(
        <Btn
          key={`PagingBtn${i}`}
          active={this.props.currPageNum === i}
          onClick={this.handleBtnClick(i)}
        >
          {i}
        </Btn>);
    }
    return (
      <Container>
        <BtnBox>
          <LeftArrowBtn
            onClick={this.handleBtnClick(this.state.currPageNum - 1)}
            disabled={this.state.currPageNum === 1}
          />
          {btns}
          <RightArrowBtn
            onClick={this.handleBtnClick(this.state.currPageNum + 1)}
            disabled={this.state.currPageNum === totalPageNum} 
          />
        </BtnBox>
      </Container>);
  }
}

Paging.propTypes = {
  totalPageNum: PropTypes.number,
  currPageNum: PropTypes.number,
  getListInfo: PropTypes.func
};
Paging.defaultProps = {
  totalPageNum: 1,
  currPageNum: 1,
  getListInfo: function () { }
};

const Container = styled.div`
  width: 100%;
  height: 7.6875rem;
  box-sizing: border-box;
  padding-top: 30px;
  display: flex;
  justify-content: flex-start;
`;
const BtnBox = styled.div`
  height: 2rem;
  width: 47.5rem;
  display: flex;
  justify-content: center;
`;
const Btn = styled.button.attrs(props => ({
  backgroundColor: props.active ? '#7B84E9' : '#eee',
  color: props.active ? '#fff' : '#333',
  cursor: props.disabled ? 'normal' : 'pointer'
}))`
  width: 2rem;
  height: 2rem;
  background-color: ${props => props.backgroundColor};
  margin-right: 1rem;
  border: none;
  color: ${props => props.color};
  :hover {
    cursor: ${props => props.cursor};
  }
`;
const ArrowBtn = styled(Btn)`
  background-image: ${props => props.backgroundImg};
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 9px 14px;
`;
const LeftArrowBtn = styled(ArrowBtn).attrs(props => ({
  backgroundImg: props.disabled ? `url(${require('../resource/information/arrow_left_un@2x.png')})` : `url(${require('../resource/information/arrow_left_kean@2x.png')})`
}))``;
const RightArrowBtn = styled(ArrowBtn).attrs(props => ({
  backgroundImg: props.disabled ? `url(${require('../resource/information/arrow_right_un@2x.png')})` : `url(${require('../resource/information/arrow_right_kean@2x.png')})`
}))``;