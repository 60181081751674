
import * as DominConfigs from './dominConstants';

/** 七牛 */
export const UPLOAD_TO_QiNiu = 'https://up-z1.qiniup.com'; // 上传七牛

/**
 * info
 *****************************/
export const GET_WEB_INFO_LIST = `${DominConfigs.PROXY_PART}${DominConfigs.PART.SOSO_WEB_INFO}/list`; 
export const GET_WEB_INFO_DETAIL = `${DominConfigs.PROXY_PART}${DominConfigs.PART.SOSO_WEB_INFO}/detail`; 
