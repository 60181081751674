import React, { Component } from 'react';
import styled from 'styled-components';
import logoImg from '../resource/home/navLogo@2x.png';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { changeNavBarActiveIdx } from '../redux/action/navBar';

const navData = [
  {
    href: '/home',
    text: '首页'
  }, {
    href: '/information',
    text: '嗖嗖资讯'
  }, {
    href: '/aboutUs',
    text: '关于我们'
  }, {
    href: '/connectUs',
    text: '联系我们'
  }
]

class HeaderNav extends Component {
  constructor(props) {
    super(props);
    this.handleNavBtnClick = this.handleNavBtnClick.bind(this);
  }

  handleNavBtnClick(idx) {
    return () => {
      this.props.changeActiveIdx(idx);
    };
  }

  render() {
    const navBtns = navData.map((val, idx) => (
      <Link to={val.href} key={`headerNav${idx}`}>
        <NavBtn active={this.props.activeIdx === idx}>
          <div className="border">
            <div className="text">{val.text}</div>
          </div>
        </NavBtn>
      </Link>
    ));
    return (
      <Container>
        <CenterView>
          <Logo src={logoImg} />
          <RightContainer>
            {navBtns}
          </RightContainer>
        </CenterView>
      </Container>
    );
  }
}

const Container = styled.header`
  width: 100vw;
  height: 3.75rem;
  background-color: #fff;
  box-shadow: 0 1px 0 0 #e5e5e5;
  display: flex;
  justify-content: center;
`;
const CenterView = styled.div`
  width: 1024px;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
`;
const Logo = styled.img`
  width: 10.3125rem;
  height: 2.3125rem;
  margin-top: 15px;
`;
const RightContainer = styled.div`
  display: flex;
  height: 100%;
`;
const NavBtn = styled.div.attrs(props => ({
  backgroundImg: props.hideDashLine ? 'none' : `url(${require("../resource/Line@2x.png")})`,
  textColor: props.active ? '#62B0FE' : '#333',
  underLineColor: props.active ? '#62B0FE' : '#fff'
}))`
  height: 100%;
  width: 6.25rem;
  vertical-align: center;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  & div.border {
    background-image: ${props => props.backgroundImg};
    background-repeat: no-repeat;
    background-position: 110% 50%;
    height: 1.125rem;
    width: 100%;
  }
  & div.text {
    overflow: visible;
    box-sizing: border-box;
    color: ${props => props.textColor};
    height: 1.125rem;
    width: 100%;
    border-right: ${props => props.dashLine};
    text-align: center;
    font-family: Helvetica;
    font-size: 14px;
    line-height: 17px;
    
    transition-duration: 0.5s;
  }
  &:hover div.text {
    transform: scale(1.1);
    color: #62B0FE;
  }
  &::after {
    position: absolute;
    width: 6.25rem;
    height: 0.25rem;
    bottom: 0.125rem;
    left: -0.0625rem;
    background-color: ${props => props.underLineColor};
    content: '';
    transition-duration: 0.5s;
  }
`;

NavBtn.defaultProps = {
  text: ''
};
const mapStateToProps = (state, ownProps) => ({
  activeIdx: state.navBarStore.activeIdx
});
const mapDispatchToProps = (dispatch, ownProps) => ({
  changeActiveIdx(idx) {
    dispatch(changeNavBarActiveIdx(idx));
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(HeaderNav);
