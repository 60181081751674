import React, { Component } from 'react';
import styled from 'styled-components';
import Content from '../component/content';

import bcgImgBigger from '../resource/connectUs/background@2x.png';
import bcgImgSmaller from '../resource/connectUs/Rectangle 14.png';
import connectUsImg from '../resource/connectUs/pic_cnnct@2x.png';

export default class ConnectUs extends Component {
  render() {
    return (
      <Container>
        <BcgImg src={bcgImgBigger}/>
        <RectImg src={bcgImgSmaller}/>
        <TitleImg src={connectUsImg}/>
        <TextBox>
          <Title>嗖嗖编程（北京）科技有限责任公司</Title>
          <Text>联系邮箱：contact@soso-code.com</Text>
          <Text>商务邮箱：business@soso-code.com</Text>
          <Text>公众号：嗖嗖编程</Text>
          </TextBox>
      </Container>
    );
  }
}

const Container = styled(Content)`
  height: 38rem;
  padding-bottom: 3.75rem;
  position: relative;
`;
const BcgImg = styled.img`
  width: 64.125rem;
  height: 25rem;
  position: absolute;
  top: 148px;
`;
const RectImg = styled.img`
  width: 21rem;
  height: 21.8125rem;
  position: absolute;
  top: 148px;
  left: 122px;
  object-fit: fill;
`;
const TitleImg = styled.img`
  width: 12.25rem;
  height: 5.875rem;
  position: absolute;
  top: 1.8125rem;
`;
const TextBox = styled.div`
  width: 21rem;
  height: 21.8125rem;
  position: absolute;
  top: 148px;
  left: 122px;
  padding-left: 1.5625rem;
  padding-top: 2rem;
  overflow: hidden;
  box-sizing: border-box;
`;
const WhiteText = styled.div`
  white-space: nowrap;
  color: #fff;
  font-family: 'FZLTZHK--GBK1-0';
`;
const Title = styled(WhiteText)`
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 1rem;
`;
const Text = styled(WhiteText)`
  font-size: 15px;
  line-height: 28px;
`;
