import React, { Component } from 'react';
import styled from 'styled-components';
import md5 from 'blueimp-md5';
import { Spin } from 'antd';
import Content from '../component/content';
import Paging from '../component/paging';
import InformationList from '../component/informationList';

import informationImg from '../resource/information/information@2x.png';
import logoImg from '../resource/information/logo@3x copy@2x.png';
import qrCodeImg from '../resource/information/qrcode.jpg';
import { launchGetWebInfoList } from '../redux/action/info-model';
import BaseController from './base/base-controller';

export default class Information extends BaseController {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
    };

    this.requestWebInfoList = this.requestWebInfoList.bind(this);
  }
  componentDidMount() {
    // this.getListDataByPage(1);

    this.requestWebInfoList();
  }

  requestWebInfoList() {
    this.changeLoadingState(true);
    launchGetWebInfoList(
      { page: this.page },
      res => {
        this.changeLoadingState(false);
        console.log(res);
        this.setState({
          ...res
        });
      },
      fail => {
        this.changeLoadingState(false);
      }
    )
  }

  getListDataByPage = (pageNum) => {
    const timeStamp = Math.floor(Date.now() / 1000);
    fetch('/webapi/webnews/getwebnews.do', {
      method: 'POST',
      body: JSON.stringify({ page: pageNum }),
      headers: new Headers({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        't': `${timeStamp}`,
        'os': 'web',
        'sign': md5(`lsan53719&${timeStamp}`)
      })
    }).then(response => {
      if (response.status === 200) {
        return response.json();
      } else {
        console.log('请求出错');
      }
    }).then(result => {
      const resData = result.result;
      this.setState({
        list: resData.list,
        pageSize: resData.pagesize,
        page: resData.page,
        totalPageNum: resData.totalpage
      });
    }).catch(err => console.log(err));
  }

  render() {
    return (
      <Spin Spinning={this.state.isLoading}>
      <Content>
        <TitleImgContainer>
          <TitleImg src={informationImg} />
        </TitleImgContainer>
        <CenterPage>
          <InformationList
            data={this.state.list}
            pageSize={this.state.pageSize} />
          <AsideBox>
            <AsideImgBox>
              <AsideImg src={logoImg} />
            </AsideImgBox>
            <AsideMiddleBox>
              <AsideMiddleImg src={qrCodeImg} />
            </AsideMiddleBox>
            <AsideBottomText>关注“嗖嗖编程”微信公众号</AsideBottomText>
            <AsideBottomText>查看更多精彩内容</AsideBottomText>
          </AsideBox>
        </CenterPage>
        <Paging
          totalPageNum={this.state.totalPageNum}
          currPageNum={this.state.page}
          getListInfo={this.getListDataByPage} />
      </Content>
      </Spin>
    );
  }
}

const TitleImgContainer = styled.div`
  background-color: transparent;
  height: 9.25rem;
`;
const TitleImg = styled.img`
  width: 16.8125rem;
  height: 5.875rem;
  object-fit: contain;
  margin-top: 1.8125rem;
`;

const CenterPage = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
const AsideBox = styled.aside`
  width: 13.75rem;
  height: 296px;
  background: #fff;
  box-shadow: 0 0 4px 0 #e5e5e5;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;
const AsideImgBox = styled.div`
  width: 5rem;
  height: 0.9375rem;
  margin-top: 3.5rem;
`;
const AsideImg = styled.img`
  width: 100%;
  height: 100%;
`;
const AsideMiddleBox = styled.div`
  margin-top: 0.875rem;
  width: 6.75rem;
  height: 6.75rem;
  background-color: #f5f5f5;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
  overflow: hidden;
`;
const AsideMiddleImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: fill;
`;
const AsideBottomText = styled.div`
  width: 100%;
  text-align: center;
  font-family: Helvetica;
  font-size: 14px;
  color: #333333;
  letter-spacing: 0;
  line-height: 22px;
`;

/* const listData = [
  {
    img: 'https://image.gcores.com/a53fad83-7c47-416a-a590-0d14b9a992c8.jpg?x-oss-process=style/original_hs',
    title: '新闻标题',
    subTitle: '2017-05-16',
    previewText: '新闻内容简介新闻内容简介新闻内容简介新闻内容，简介新闻内容简介新闻内容简介新闻内容简介新闻内新闻内容简介新闻内容简介新闻内容简介新闻内容简介。',
    bottomText: '查看全文'
  },
  {
    img: 'https://image.gcores.com/eedea4d5-6c60-46d1-a189-3d608d3370ed.jpg?x-oss-process=style/original_hs',
    title: '新闻标题',
    subTitle: '2017-05-16',
    previewText: '新闻内容简介新闻内容简介新闻内容简介新闻内容，简介新闻内容简介新闻内容简介新闻内容简介新闻内新闻内容简介新闻内容简介新闻内容简介新闻内容简介。',
    bottomText: '查看全文'
  },
  {
    img: 'https://image.gcores.com/309171ae-0298-4c5e-93a6-664b277cd8dc.jpg?x-oss-process=style/original_hs',
    title: '新闻标题',
    subTitle: '2017-05-16',
    previewText: '新闻内容简介新闻内容简介新闻内容简介新闻内容，简介新闻内容简介新闻内容简介新闻内容简介新闻内新闻内容简介新闻内容简介新闻内容简介新闻内容简介。',
    bottomText: '查看全文'
  },
  {
    img: 'https://image.gcores.com/23556637-e398-4f7b-9a3c-33b84326a737.jpg?x-oss-process=style/original_hs',
    title: '新闻标题',
    subTitle: '2017-05-16',
    previewText: '新闻内容简介新闻内容简介新闻内容简介新闻内容，简介新闻内容简介新闻内容简介新闻内容简介新闻内新闻内容简介新闻内容简介新闻内容简介新闻内容简介。',
    bottomText: '查看全文'
  },
  {
    img: 'https://image.gcores.com/ddc57b4b-2cba-46b1-b81f-405b3d9881dc.jpg?x-oss-process=style/original_hs',
    title: '新闻标题',
    subTitle: '2017-05-16',
    previewText: '新闻内容简介新闻内容简介新闻内容简介新闻内容，简介新闻内容简介新闻内容简介新闻内容简介新闻内新闻内容简介新闻内容简介新闻内容简介新闻内容简介。',
    bottomText: '查看全文'
  }
]; */
