import React, { Component } from 'react';
import Content from '../../component/content';
import informationImg from '../../resource/information/information@2x.png';
import logoImg from '../../resource/information/logo@3x copy@2x.png';
import qrCodeImg from '../../resource/information/qrcode.jpg';
import { launchGetWebInfoDetail, launchGetWebInfoList } from '../../redux/action/info-model';
import BaseController from '../base/base-controller';
import Paging from '../../component/paging';
import styled from 'styled-components';
import { getQueryVariable } from '../../util/helper/url-helper';

export default class InfoDetail extends BaseController {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,

      detail: {}
    };

    this.tag = getQueryVariable('tag');
  
    this.requestInfoDetail = this.requestInfoDetail.bind(this);
  }
  componentDidMount() {
    this.requestInfoDetail();
  }

  requestInfoDetail() {
    this.changeLoadingState(true);
    launchGetWebInfoDetail(
        { id: this.tag },
        res => {
            this.setState({
                detail: res.detail
            });
            this.changeLoadingState(false);
        },
        fail => {
            this.changeLoadingState(false);
        }
    );
  }


  render() {
    let { detail } = this.state;

    let content = undefined;
    if (detail.infocontent) {
        content = JSON.parse(detail.infocontent);
    }

    return (
      
      <Content>
        {/* <TitleImgContainer>
          <TitleImg src={informationImg} />
        </TitleImgContainer> */}
        <CenterPage>
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', marginTop: 20, marginBottom: 30 }}>
                    <span style={{ fontSize: 40, color: '#000' }}>{detail.title}</span>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    {
                        content && content.map((aContent, index) => {
                            if (aContent.type === 1) {
                                return (
                                    <div key={`content_${index}`} style={{ display: 'flex', flexDirection: 'column', marginBottom: 10 }}>
                                        <span>{aContent.content}</span>
                                    </div>
                                )
                            } else if (aContent.type === 10) {
                                return (
                                    <div key={`content_${index}`} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 20, marginBottom: 20 }}>
                                        <img style={{ width: 600, height: 600, objectFit: 'cover'}} src={aContent.url} />
                                    </div>
                                )
                            }
                           
                        })
                    }
                </div>

            </div>
        </CenterPage>
      </Content>
    );
  }
}


const TitleImgContainer = styled.div`
  background-color: transparent;
  height: 9.25rem;
`;
const TitleImg = styled.img`
  width: 16.8125rem;
  height: 5.875rem;
  object-fit: contain;
  margin-top: 1.8125rem;
`;

const CenterPage = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: space-between;
`;
const AsideBox = styled.aside`
  width: 13.75rem;
  height: 296px;
  background: #fff;
  box-shadow: 0 0 4px 0 #e5e5e5;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;
const AsideImgBox = styled.div`
  width: 5rem;
  height: 0.9375rem;
  margin-top: 3.5rem;
`;
const AsideImg = styled.img`
  width: 100%;
  height: 100%;
`;
const AsideMiddleBox = styled.div`
  margin-top: 0.875rem;
  width: 6.75rem;
  height: 6.75rem;
  background-color: #f5f5f5;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
  overflow: hidden;
`;
const AsideMiddleImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: fill;
`;
const AsideBottomText = styled.div`
  width: 100%;
  text-align: center;
  font-family: Helvetica;
  font-size: 14px;
  color: #333333;
  letter-spacing: 0;
  line-height: 22px;
`;

/* const listData = [
  {
    img: 'https://image.gcores.com/a53fad83-7c47-416a-a590-0d14b9a992c8.jpg?x-oss-process=style/original_hs',
    title: '新闻标题',
    subTitle: '2017-05-16',
    previewText: '新闻内容简介新闻内容简介新闻内容简介新闻内容，简介新闻内容简介新闻内容简介新闻内容简介新闻内新闻内容简介新闻内容简介新闻内容简介新闻内容简介。',
    bottomText: '查看全文'
  },
  {
    img: 'https://image.gcores.com/eedea4d5-6c60-46d1-a189-3d608d3370ed.jpg?x-oss-process=style/original_hs',
    title: '新闻标题',
    subTitle: '2017-05-16',
    previewText: '新闻内容简介新闻内容简介新闻内容简介新闻内容，简介新闻内容简介新闻内容简介新闻内容简介新闻内新闻内容简介新闻内容简介新闻内容简介新闻内容简介。',
    bottomText: '查看全文'
  },
  {
    img: 'https://image.gcores.com/309171ae-0298-4c5e-93a6-664b277cd8dc.jpg?x-oss-process=style/original_hs',
    title: '新闻标题',
    subTitle: '2017-05-16',
    previewText: '新闻内容简介新闻内容简介新闻内容简介新闻内容，简介新闻内容简介新闻内容简介新闻内容简介新闻内新闻内容简介新闻内容简介新闻内容简介新闻内容简介。',
    bottomText: '查看全文'
  },
  {
    img: 'https://image.gcores.com/23556637-e398-4f7b-9a3c-33b84326a737.jpg?x-oss-process=style/original_hs',
    title: '新闻标题',
    subTitle: '2017-05-16',
    previewText: '新闻内容简介新闻内容简介新闻内容简介新闻内容，简介新闻内容简介新闻内容简介新闻内容简介新闻内新闻内容简介新闻内容简介新闻内容简介新闻内容简介。',
    bottomText: '查看全文'
  },
  {
    img: 'https://image.gcores.com/ddc57b4b-2cba-46b1-b81f-405b3d9881dc.jpg?x-oss-process=style/original_hs',
    title: '新闻标题',
    subTitle: '2017-05-16',
    previewText: '新闻内容简介新闻内容简介新闻内容简介新闻内容，简介新闻内容简介新闻内容简介新闻内容简介新闻内新闻内容简介新闻内容简介新闻内容简介新闻内容简介。',
    bottomText: '查看全文'
  }
]; */
