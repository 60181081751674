import React from 'react';
import styled, { keyframes } from 'styled-components';

import { PartTextBox, PartTextTitle, PartTextSubTitle } from './partText';

import BcgImg from '../../resource/mobileHome/solution/bcg.png';
import LightImg from '../../resource/mobileHome/solution/light.png';
import ManImg from '../../resource/mobileHome/solution/man.png';
import ForbidImg from '../../resource/mobileHome/solution/forbid.png';
import IpadImg from '../../resource/mobileHome/solution/pad.png';
import BugImg from '../../resource/mobileHome/solution/bug.png';
import EnterImg from '../../resource/mobileHome/solution/enter.png';
import EnterActiveImg from '../../resource/mobileHome/solution/enter_active.png';
import IphoneImg from '../../resource/mobileHome/solution/iphone.png';

const Box = styled.div`
  height: 29.8125rem;
  width: 100%;
  position: relative;
  overflow: hidden;
`;
const Img = styled.img`
  position: absolute;
`;
const Bcg = styled(Img)`
  width: 100%;
  top: 0;
`;
const Light = styled(Img)`
  width: 0.625rem;
  height: 2.5313rem;
  top: 2.4063rem;
  left: 18.4063rem;
`;
const Man = styled(Img)`
  width: 3.6875rem;
  top: 10.9375rem;
  right: 1.4375rem;
`;
const hideForbid = keyframes`
  0% {
    visibility: hidden;
  }
  90% {
    visibility: hidden;
  }
  100% {visibility: visible;}
`;
const Forbid = styled(Img)`
  width: 2.4375rem;
  height: 2.4375rem;
  top: 6.6875rem;
  left: 9.4688rem;
  animation: ${hideForbid} 2.5s infinite;
`;
const Ipad = styled(Img)`
  width: 9.25rem;
  height: 10.375rem;
  top: 4.2813rem;
  left: 4.3125rem;
`;
const bugMove = keyframes`
  0% {
    transform: rotate(0) translateX(0) translateY(0);
  }
  3% {
    transform: translateX(-3.125rem) translateY(1.125rem) rotate(40deg);
    /* top: 130px;
    left: 110px; */
  }
  19% {
    /* transform: rotate(30deg); */
    transform: translateX(-3.125rem) translateY(1.125rem) rotate(40deg);
    /* top: 130px;
    left: 110px; */
  }
  23% {
    /* transform: rotate(15deg); */
    transform: translateX(-1.5625rem) translateY(0.9375rem) rotate(35deg);
    /* top: 127px;
    left: 135px; */
  }
  38% {
    transform: translateX(-1.5625rem) translateY(0.9375rem) rotate(35deg);
    /* top: 127px;
    left: 135px; */
  }
  41% {
    transform: rotate(0) translateX(0) translateY(0);;
    /* top: 117px;
    left: 160px; */
  }
  100% {
    transform: rotate(0) translateX(0) translateY(0);
    /* top: 117px;
    left: 160px; */
  }
`;
const Bug = styled(Img)`
  width: 1.25rem;
  height: 1.25rem;
  top: 7.3125rem;
  left: 10rem;
  animation: ${bugMove} 2.5s infinite;
`;
const Enter = styled(Img)`
  width: 3.1563rem;
  height: 3.1563rem;
  top: 10.3125rem;
  left: 9.6875rem;
`;
const enterHide = keyframes`
  0% { visibility: hidden; }
  80% { visibility: hidden; }
  81% { visibility: visible; }
  90% { visibility: hidden; }
  100% { visibility: hidden; }
`;
const EnterActive = styled(Img)`
  width: 3.1563rem;
  height: 3.1563rem;
  top: 10.3125rem;
  left: 9.6875rem;
  visibility: hidden;
  animation: ${enterHide} 2.5s infinite;
`;
const Iphone = styled(Img)`
  width: 3.875rem;
  height: 6.8125rem;
  top: 7.7813rem;
  left: 12.375rem;
`;
const TextBox = styled(PartTextBox)`
  top: 17.9375rem;
`;

export default function () {
  return (
    <Box>
      <Bcg src={BcgImg} />
      <Light src={LightImg} />
      <Man src={ManImg} />
      <Ipad src={IpadImg} />
      <Bug src={BugImg} />
      <Forbid src={ForbidImg} />
      <Enter src={EnterImg} />
      <EnterActive src={EnterActiveImg} />
      <Iphone src={IphoneImg} />
      <TextBox>
        <PartTextTitle>解决方案</PartTextTitle>
        <PartTextSubTitle>嗖嗖编程紧跟移动互联网趋势，一直作为首批研发团队</PartTextSubTitle>
        <PartTextSubTitle>具有丰富的产品设计及研发经验</PartTextSubTitle>
      </TextBox>
    </Box>
  );
};
