import React, { Component } from 'react';
import styled from 'styled-components';

import Content from '../component/content';

import aboutUsImg from '../resource/aboutUs/pic_about@2x.png';
import techCoopImg from '../resource/aboutUs/tech_coop@2x.png';
import productDesignImg from '../resource/aboutUs/product_design2x.png';
import outsourcingImg from '../resource/aboutUs/outsourcing@2x.png';

export default class AboutUs extends Component {
  render() {
    return (
      <Content>
        <TitleImgContainer>
          <TitleImg src={aboutUsImg} />
        </TitleImgContainer>
        <WhiteContainer>
          <WhiteList>
            <WhiteListImg src={techCoopImg} />
            <TextContainer>
              <Title>技术合作</Title>
              <Text>
              APP，网站，小程序等多种服务项目；合作，外包等多种服务方式；透明的流程，先进的交付工具，让您放心地了解进度。与靠谱的技术合作，你只差一个嗖嗖编程。精英团队，领先人才，前沿技术，带给您的是更优质的服务，更优惠的价格。
              </Text>
            </TextContainer>
          </WhiteList>
          <WhiteList>
            <TextContainer>
              <Title>产品设计</Title>
              <Text>
                  产品设计，一直是嗖嗖编程的强项，这也是为什么更多的合作伙伴愿意选择嗖嗖编程。不同于其他公司的千篇一律，我们的设计更擅于表达产品的亮点，丰富产品的表现，专注于用户的体验。让产品更加出众，在众多竞品中脱颖而出。
              </Text>
            </TextContainer>
            <WhiteListImg src={productDesignImg} />
          </WhiteList>
          <WhiteList>
            <WhiteListImg src={outsourcingImg} />
            <TextContainer>
              <Title>解决方案</Title>
              <Text>
                  嗖嗖编程紧跟移动互联网趋势，一直作为首批研发团队，先后研发在线教育，电商平台，健康医疗，旅游服务等多个领域的解决方案。领先进入短视频，广告推广等领域。具有丰富的产品设计及研发经验，帮助众多客户打造满意的产品。
              </Text>
            </TextContainer>
          </WhiteList>
        </WhiteContainer>
      </Content>
    );
  }
}

const TitleImgContainer = styled.div`
  background-color: transparent;
  height: 9.375rem;
`;
const TitleImg = styled.img`
  width: 9.8125rem;
  height: 5.875rem;
  object-fit: contain;
  margin-top: 29px;
`;
const WhiteContainer = styled.div`
  background: #fff;
  margin-bottom: 9.25rem;
  border-top: 1px solid #e5e5e5;
`;
const WhiteList = styled.div`
  display: flex;
  justify-content: space-between;
  height: 17.25rem;
`;
const WhiteListImg = styled.img`
  height: 100%;
  width: 36.75rem;
`;
const TextContainer = styled.div`
  height: 100%;
  width: 22.25rem;
  box-sizing: border-box;
  margin-left: 2.25rem;
  margin-right: 40px;
`;
const Title = styled.div`
  width: 100%;
  height: 30px;
  line-height: 30px;
  font-family: PingFangSC-Medium;
  margin-top: 1.875rem;
  margin-bottom: 0.5625rem;
  color: #363636;
`;
const Text = styled.div`
  width: 100%;
  height: 150px;
  overflow: hidden;
  color: #666666;
  font-family: Helvetica;
  font-size: 16px;
  line-height: 30px;
`