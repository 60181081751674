import React, { Component } from 'react';
import styled from 'styled-components';
import HeaderNav from './component/headerNav';
import Footer from './component/footer';
import HomePage from './page/home';
import AboutUsPage from './page/aboutUs';
import ConnectUsPage from './page/connectUs';
import InfoDetail from './page/info/info-detail';
import Information from './page/information';
import MobileHomePage from './page/mobileHome';
import { connect } from 'react-redux';
import * as actions from './redux/action/navBar';
import {
  Route,
  Switch,
  Redirect
} from 'react-router-dom';

class App extends Component {
  componentWillMount() {
    const userAgent = window.navigator.userAgent.toLowerCase();
    window.abc = userAgent;
    let isMobile = false;
    if (
      userAgent.includes("ipad") ||
      (userAgent.includes("android") && userAgent.includes("mobile")) ||
      (userAgent.includes("android") && userAgent.includes("mozilla")) ||
      (userAgent.includes("android") && userAgent.includes("opera")) ||
      userAgent.includes("ucweb7") ||
      userAgent.includes("iphone")
    ) {
      isMobile = true;
    }
    this.setState({ isMobile });
  }
  toInformationPage = () => {
    this.props.changeActiveIdx(1);
    return <Information />;
  }
  toAboutUsPage = () => {
    this.props.changeActiveIdx(2);
    return <AboutUsPage />;
  }
  toHomePage = () => {
    this.props.changeActiveIdx(0);
    return <HomePage />;
  }
  toConnectUsPage = () => {
    this.props.changeActiveIdx(3);
    return <ConnectUsPage />;
  }
  toInfoDetail = () => {
    this.props.changeActiveIdx(1);
    return <InfoDetail />;
  }
  mainPage = () => {
    if (this.state.isMobile) {
      return <Route path='/' component={MobileHomePage} />;
    } else {
      return (
        <Switch>
          <Route path='/' exact render={this.toHomePage} />
          <Route path='/information' render={this.toInformationPage} />
          <Route path='/aboutUs' render={this.toAboutUsPage} />
          <Route path='/home' render={this.toHomePage} />
          <Route path='/connectUs' render={this.toConnectUsPage} />
          <Route path='/infodetail' render={this.toInfoDetail} />
          <Redirect to="/home" />
        </Switch>
      );
    }
  }
  render() {
    return (
      <div className="App">
        {this.state.isMobile ? null : <HeaderNav />}
        <Main>
          {this.mainPage()}
        </Main>
        {this.state.isMobile ? null : <Footer />}
      </div>
    );
  }
}

const Main = styled.main`
  width: 100vw;
  background-color: #f9f9f9;
  display: flex;
  justify-content: center;
  flex: 1;
`;

const mapDispatchToProps = (dispatch, ownProps) => ({
  changeWelComePageText() {
    // dispatch(actions.changeHome('Boy♂Next♂Door')) 
  },
  changeActiveIdx(idx = 0) {
    dispatch(actions.changeNavBarActiveIdx(idx));
  }
});
const mapStateToProps = (state, ownProps) => {
  return {
    name: state.哲学.data
  }
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
