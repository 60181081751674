import React, { Component } from 'react';
import styled from 'styled-components';

const Container = styled.header`
  width: 100vw;
  height: 6.25rem;
  background-color: #7B84E9;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const CenterText = styled.span`
  font-size: 13px;
  color: #333;
  font-family: Helvetica;
  text-align: center;
  cursor: pointer;
`;

export default class Footer extends Component {
  render() {
    return (
      <Container>
        <CenterText onClick={() => {
          window.open('https://beian.miit.gov.cn/', '_blank');
        }}>京ICP备17020842号-1</CenterText>
      </Container>
    );
  }
}
