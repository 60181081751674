import React, { Component } from 'react';
import styled from 'styled-components';
import Bannera from '../resource/home/banner/text@2x.png';
import BannerBcgImg from '../resource/home/banner/bcgimg@2x.png';
import Bannerleft from '../resource/home/banner/left@2x.png';
import Bannerright from '../resource/home/banner/right@2x.png';

import OurServicequotation from '../resource/home/ourservice/quotation.png';
import OurServicegroup1 from '../resource/home/ourservice/1@2x.png';
import OurServicegroup2 from '../resource/home/ourservice/2@2x.png';
import OurServicegroup3 from '../resource/home/ourservice/3@2x.png';

import ServiceTypeAppDev from '../resource/home/serviceType/5@2x.png';
import ServiceTypeWebDev from '../resource/home/serviceType/computer1@2x.png';
import ServiceTypeMiniDev from '../resource/home/serviceType/web-phone@2x.png';
import ServiceTypeProductDesign from '../resource/home/serviceType/6@2x.png';
import ServiceTypeUiDesign from '../resource/home/serviceType/Ruler@2x.png';
import ServiceTypeInteractionDesign from '../resource/home/serviceType/7@2x.png';
import ServiceTypePublicDev from '../resource/home/serviceType/web@2x.png';
import ServiceTypeTestService from '../resource/home/serviceType/File bag@2x.png';
import ServiceTypeCustomizationDev from '../resource/home/serviceType/Repair@2x.png';

import Solution_1 from '../resource/home/solution/1@2x.png';
import Solution_2 from '../resource/home/solution/2@2x.png';
import Solution_3 from '../resource/home/solution/3@2x.png';
import Solution_4 from '../resource/home/solution/4@2x.png';

import Content from '../component/content';

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.bannerEle = React.createRef();
  }
  bannerAnimation() {
    //banner动效
    var origin = {
      X: 1440 / 2,
      Y: window.innerHeight / 2
    };
    var o = document.querySelector('.bannershow');
    var c = document.querySelector('.changeimg');
    document.querySelector('.banner_3d').onmouseover = function () {
      document.querySelector('.banner_3d').addEventListener('mousemove', function (e) {
        var rotate = {
          x: 1 - e.pageY / origin.Y,
          y: e.pageX / origin.X - 1
        };

        var transition = "0s";
        var transform = "rotateX(" + rotate.x * 10 + "deg) rotateY(" + rotate.y * 10 + "deg)"; //变换效果
        var translate = "translate3d(" + rotate.x * 5 + "px," + rotate.x * 5 + "px,0px)"; //变换效果

        o.style.transition = transition;
        o.style.transform = transform;

        c.style.transition = transition;
        c.style.transform = translate;
      });
    };

    document.querySelector('.banner_3d').onmouseout = function () {
      o.style.transition = "all 1.5s ease";
      o.style.transform = "rotateX(0deg) rotateY(0deg)";

      c.style.transition = "transform 0.3s ease-in-out;";
      c.style.translate = "translate3d(0px,0px,0px)";
    }
  }
  handleBannerMouseMove = (e) => {
    const banner = this.bannerEle.current;
    const overlay = this.translateEle;
    const origin = {
      X: 1440 / 2,
      Y: window.innerHeight / 2
    };
    const rotate = {
      x: 1 - e.pageY / origin.Y,
      y: e.pageX / origin.X - 1
    };
    const transitionDuration = "0s";
    const transform = "rotateX(" + rotate.x * 10 + "deg) rotateY(" + rotate.y * 10 + "deg)"; //变换效果
    const translate = "translate3d(" + rotate.x * 5 + "px," + rotate.x * 5 + "px,0)"; //变换效果

    banner.style.transitionDuration = transitionDuration;
    banner.style.transform = transform;
    overlay.style.transitionDuration = transitionDuration;
    overlay.style.transform = translate;
  }
  handleBannerMouseOut = () => {
    const banner = this.bannerEle.current;
    const overlay = this.translateEle;
    banner.style.transition = "all 1.5s ease";
    banner.style.transform = "rotateX(0deg) rotateY(0deg)";

    overlay.style.transition = "transform 0.3s ease-in-out;";
    overlay.style.translate = "translate3d(0,0,0)";
  }
  render() {
    return (
      <Content>
        <Banner
          ref={this.bannerEle}
          onMouseMove={this.handleBannerMouseMove}
          onMouseOut={this.handleBannerMouseOut}
        >
          <img src={BannerBcgImg} className='bcg-img' alt='' />
          <div ref={(ele) => { this.translateEle = ele; }}>
            <img src={Bannerleft} className='left-img' alt='' />
            <img src={Bannerright} className='right-img' alt='' />
            <img src={Bannera} className='center-text' alt='' />
          </div>
        </Banner>
        <OurService>
          <OurServiceTitle>我们提供的服务</OurServiceTitle>
          <OurServiceBtnList>
            <OurServiceBtnImg src={OurServicegroup1} />
            <OurServiceBtnImg src={OurServicegroup2} />
            <OurServiceBtnImg src={OurServicegroup3} />
          </OurServiceBtnList>
          <img src={OurServicequotation} className="quotation-mark" alt="" />
        </OurService>,
          <ServiceType>
          <ServiceTypeTitle>服务种类</ServiceTypeTitle>
          <ServiceTypeList data={ServiceTypeListData} />
        </ServiceType>,
          <Solution>
          <SolutionTitle>行业解决方案</SolutionTitle>
          <SolutionBtnGroup>
            <SolutionBtnGroupImg src={Solution_1} />
            <SolutionBtnGroupImg src={Solution_2} />
            <SolutionBtnGroupImg src={Solution_3} />
            <SolutionBtnGroupImg src={Solution_4} />
          </SolutionBtnGroup>
        </Solution>
      </Content>
    );
  }
}

const Banner = styled.div`
  margin-top: 3.6875rem;
  height: 25.6875rem;
  position: relative;
  overflow: hidden;
  div {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
  }
  img.bcg-img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    /* object-position: 0px -2px; */
  }
  img.center-text {
    z-index: 1;
    position: absolute;
    top: 8.1875rem;
    left: 15.9375rem;
    width: 32.125rem;
    height: 10rem;
    /* z-index: 2; */
  }
  img.left-img {
    width: 39.4375rem;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    /* z-index: 1; */
  }
  img.right-img {
    width: 39.4375rem;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    /* z-index: 1; */
  }
`;
const SectionTitle = styled.span`
  font-family: PingFangSC-Medium;
  font-size: 30px;
  color: #6E78BB;
  letter-spacing: 0;
  text-align: center;
`;
const OurService = styled.div`
  height: 28.625rem;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  & img.quotation-mark {
    width: 16.1875rem;
    height: 15.0625rem;
    position: absolute;
    top: 3rem;
    left: 67px;
  }
`;
const OurServiceTitle = styled(SectionTitle)`
  text-align: right;
  margin-top: 6.75rem;
  position: relative;
  ::after {
    content: '';
    width: 3.4375rem;
    height: 0.3125rem;
    background-color: #6E78BB;
    border-radius: 0.1563rem;
    position: absolute;
    left: 0;
    top: 3.0625rem;
  }
`;
const OurServiceBtnList = styled.div`
  height: 9.9375rem;
  display: flex;
  position: relative;
  flex-flow: row nowrap;
  margin-top: 2.625rem;
  `;
const OurServiceBtnImg = styled.img`
  width: 14.6875rem;
  height: 9.9375rem;
  border-radius: 8px;
  box-shadow: 0px 2px 9px rgba(0,0,0,0.3);
  margin-left: 1.25rem;
`;

const ServiceTypeListData = [
  {
    img: ServiceTypeAppDev,
    title: 'APP开发',
    description: ['iOS端/Android', '端完美兼容各机型']
  }, {
    img: ServiceTypeWebDev,
    title: '网站开发',
    description: ['PC端/移动', '网站，多语言开发']
  }, {
    img: ServiceTypeMiniDev,
    title: '小程序开发',
    description: ['上线小程序，抢占流', '量红利，实现营收激增']
  }, {
    img: ServiceTypeProductDesign,
    title: '产品设计',
    description: ['需求分析/市', '场调研/原型设计']
  }, {
    img: ServiceTypeUiDesign,
    title: 'UI设计',
    description: 'APP/网站/VI设计'
  }, {
    img: ServiceTypeInteractionDesign,
    title: '交互设计',
    description: '提升用户体验'
  }, {
    img: ServiceTypePublicDev,
    title: '公众号开发',
    description: ['主流的线上线下', '微信互动营销方式']
  }, {
    img: ServiceTypeTestService,
    title: '测试服务',
    description: ['安全测试/功能测试', '性能测试/兼容测试']
  }, {
    img: ServiceTypeCustomizationDev,
    title: '定制开发',
    description: '个性化服务，全网开发'
  }
];

const ServiceType = styled.div`
  width: 100%;
  height: 44.6875rem;
  background-color: #fff;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
`;
const ServiceTypeTitle = styled(SectionTitle)`
  margin-top: 3.0625rem;
  margin-bottom: 3.25rem;
`;
const ServiceTypeList = props => {
  const Container = styled.div`
    display: flex; 
    flex-flow: column nowrap;
    padding-left: 12.5rem;
    padding-right: 12.5rem;
    justify-content: space-between;
    align-items: flex-start;
    height: 517px;
    width: 39rem;
  `;
  const Row = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
  `;
  if (Array.isArray(props.data) && props.data.length === 9) {
    const rows = []
    let row = [];
    for (let i = 0; i < 3; i++ , row = []) {
      for (let j = 0; j < 3; j++) {
        row.push(<ServiceTypeListItem {...props.data[i * 3 + j]} key={'ServiceTypelistitem' + i + j} />);
      }
      rows.push(<Row key={`row${i}`}>{row}</Row>);
    }
    return <Container>{rows}</Container>;
  } else {
    return null;
  }
};

const ServiceTypeListItem = props => {
  const Outer = styled.div`
    width: 8rem;
    height: 8.625rem;
    /* background-color: #e8e8e8; */
    border: 1px solid #fff;
    box-sizing: border-box;
    display: flex;
    flex-flow: column nowrap;
    /* justify-content: space-around; */
    align-items: center;
    :hover {
      cursor: pointer;
    }
  `;
  const Img = styled.img`
    max-height: 3.4375rem;
    max-width: 6rem;
    object-fit: contain;
  `;
  const Title = styled.span`
    font-family: PingFangSC-Semibold;
    font-size: 16px;
    text-align: center;
    color: #6F79BC;
    margin-top: 0.9375rem;
    margin-bottom: 0.5625rem;
  `;
  const DescriptionContainer = styled.div`
    height: 2rem;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  `;
  const DescriptionText = styled.span`
    font-family: PingFangSC-Light;
    font-size: 11px;
    letter-spacing: 0;
    color: #6F79BC;
    overflow: visible;
    white-space: nowrap;
  `;
  const Description = props => {
    let descriptionText;
    if (Array.isArray(props.children)) {
      descriptionText = props.children.map((val, idx) => <DescriptionText key={'descriptionText' + idx}>{val}</DescriptionText>);
    } else if (typeof props.children === 'string') {
      descriptionText = <DescriptionText>{props.children}</DescriptionText>;
    } else {
      return null;
    }
    return (
      <DescriptionContainer>
        {descriptionText}
      </DescriptionContainer>
    );
  };
  return (
    <Outer>
      <Img src={props.img} />
      <Title>{props.title}</Title>
      <Description>{props.description}</Description>
    </Outer>
  );
};

const Solution = styled.div`
  height: 30.0625rem;
  width: 100%;
  background: transparent;
  overflow: hidden;
`;
const SolutionTitle = styled(SectionTitle)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5.6875rem;
  margin-bottom: 2.3125rem;
  
`;
const SolutionBtnGroup = styled.div`
  width: 100%;
  height: 11.4375rem;
  display: flex;
  flex-flow: row nowrap;
  background-color: transparent;
`;
const SolutionBtnGroupImg = styled.img`
  width: 16rem;
  height: 11.4375rem;
`;